angular.module('LeasePilot').directive('shareModal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  'ApiService',
  'LeaseEditorService',
  'IterationService',
  function(
    $rootScope,
    $mdDialog,
    $http,
    ApiService,
    LeaseEditorService,
    IterationService,
  ) {
    libx.browser.require('/lp-ext/export_helper.js');

    return {
      restrict: 'E',
      templateUrl: 'share_modal.html',
      link: function($scope) {
        var eventName = 'getLease';
        var companyUsers = [];
        var externalUsers = [];
        var availableTags = [];
        var attachErrorMessage =
            'Oops... something went wrong with the attachment process, please wait a couple of seconds and try again.';
        var shareErrorMessage =
            'Oops... something went wrong with the send mail process, please wait a couple of seconds and try again.';

        $scope.files = {};
        $scope.bcc = true;
        $scope.disabled = true;
        $scope.processing = false;
        $scope.hasTenantShare =
          $rootScope.lease.company.companyProfile.hasTenantShare;

        $scope.changeTemplate = function() {
          $scope.subject = $scope.template.subject;
          $scope.message = $scope.template.message;
        };

        $scope.clearText = function() {
          $scope.message = '';
        };

        function initTemplates() {
          $scope.templates = _.chain($rootScope.lease.company.emailTemplates)
            .filter(function(t) {
              return (
                t.category === 'share' &&
                t.documentType === $rootScope.lease.type
              );
            })
            .sortBy('order')
            .value();
          $scope.template = $scope.templates[0] || {};
          $scope.changeTemplate();
        }

        initTemplates();

        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          return regex.test(email);
        }

        var getContactsList = (function() {
          $http({
            method: 'GET',
            url: '/api/share/get_contacts_list',
          }).then(
            function(response) {
              companyUsers = response.data.company_users;
              externalUsers = response.data.external_users;
              availableTags = _.union(companyUsers, externalUsers);
              availableTags = _.uniq(availableTags);
              setTagIt();
            },
            function() {
              $mdDialog.cancel();
            },
          );
        })();

        function valueTagit(event) {
          var elem = event.target;
          var width = 40;
          var lenght = 0;
          if (elem.value.length) {
            lenght = (elem.value.length + 2) * 8;
          } else {
            elem.classList.remove('error');
          }
          width = lenght > 40 ? lenght : width;
          elem.style.width = width + 'px';
        }

        $('#sendTo').bind('paste', function(e) {
          var value = e.originalEvent.clipboardData.getData('text');
          var width = (value.length + 2) * 8;
          e.target.style.width = width + 'px';
        });

        $('#sendTo').keyup(function(e) {
          valueTagit(e);
        });

        function setTagIt() {
          $('#sendTo').tagit({
            tagSource: availableTags,
            autocomplete: {
              delay: 0,
              minLength: 2,
              source: availableTags,
            },
            itemName: 'listEmail',
            fieldName: 'email',
            showAutocompleteOnFocus: false,
            placeholderText: 'email',
            beforeTagAdded: function(event, ui) {
              var elem = event.target.getElementsByTagName('input');
              elem = elem[elem.length - 1];
              var validate = isEmail(ui.tagLabel);
              if (validate) {
                elem.classList.remove('error');
              } else {
                elem.classList.add('error');
              }
              return validate;
            },
            afterTagAdded: function() {
              $scope.disabled = false;
              $scope.$apply();
            },
            afterTagRemoved: function() {
              if (!$('#sendTo').tagit('assignedTags').length) {
                $scope.disabled = true;
                $scope.$apply();
              }
            },
          });
        }

        $scope.removeBcc = function() {
          $scope.bcc = false;
        };

        $scope.addBcc = function() {
          $scope.bcc = true;
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        function createFormData() {
          var path = pluralize($rootScope.lease.type).toLowerCase();
          var formData = new FormData();
          formData.append('lease_id', $rootScope.lease.id);
          formData.append('subject', $scope.subject || '');
          formData.append('message', $scope.message || '');
          formData.append(
            'url',
            [window.location.origin, path, $rootScope.lease.id, 'edit'].join(
              '/',
            ),
          );
          formData.append('contacts_list', JSON.stringify($scope.to));
          formData.append('bcc', $scope.bcc);
          if ($scope.files.redLine) {
            formData.append('redLine', $scope.files['redLine'].file);
            formData.append('redLineName', $scope.files['redLine'].name);
          }
          if ($scope.files.share) {
            formData.append('file', $scope.files['share'].file);
            formData.append('fileName', $scope.files['share'].name);
          }
          return formData;
        }

        function sendMail() {
          $http({
            method: 'POST',
            url: '/api/share/share_by_mail',
            headers: {
              'Content-Type': undefined,
            },
            data: createFormData(),
            transformRequest: angular.identity,
          }).then(
            function() {
              $scope.processing = false;
              $scope.showMask = false;
              $mdDialog.cancel();
            },
            function(error) {
              console.log(error);
              $scope.showMask = false;
              alert(shareErrorMessage);
              $mdDialog.cancel();
            },
          );
        }

        function download() {
          if (window.lease) {
            downloadManager.prepareDownload(eventName,$scope);
          }
          else{
            var iframe = angular.element('<iframe/>', {
              src:
                '/' +
                pluralize($rootScope.lease.type).toLowerCase() +
                '/' +
                $rootScope.lease.id,
              name: 'download',
              style:
                'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
              'event-name': eventName,
            });

            angular.element('body').append(iframe);
          }

        }

        function showExternalUserDialog(addresses) {
          var status,
            confirm = $mdDialog.confirm({
              skipHide: true,
              template:
                '<div id="confirm-share-header">' +
                '<h2 class="modal-header">Share</h2>' +
                '<div class="md-dialog-content">' +
                '<span>"' +
                addresses.join(', ') +
                '" </span>' +
                '<span>' +
                (addresses.length == 1 ? ' is' : ' are') +
                '</span>' +
                '<span> not a member of your organization. Are you sure you want to continue?</span>' +
                '<div class="modal-actions">' +
                '<button class="btn blue" ng-click="dialog.hide()">OK</button>' +
                '<button class="btn blue-outline" ng-click="dialog.abort()">Cancel</button>' +
                '</div>' +
                '</div>' +
                '</div>',
            });

          $mdDialog
            .show(confirm)
            .then(function() {
              status = 'ok';
              $scope.processing = true;
              $scope.showMask = true;

              IterationService.$post(
                `/api/leases/${$rootScope.lease.id}/close_iteration`,
              );
              $rootScope.lease.iterationNumber++;
              LeaseEditorService.setLeaseTitle();

              if (window.updateCurrentDraft) {
                window.updateCurrentDraft(
                  LeaseEditorService.getLeaseId(),
                  moment().format('YYYY-MM-DDThh:mm:ssZ'));
              }

              if ($scope.files.share) {
                sendMail();
              } else {
                download();
              }
            })
            .finally(function() {
              $scope.disabled = status === 'ok' ? true : false;
            });
        }

        $scope.shareToS = function() {
          $scope.disabled = true;
          $scope.to = $('#sendTo').tagit('assignedTags');

          let formData = new FormData();
          let guid = libx.newGuid();
          formData.append('lease_id', $rootScope.lease.id);
          formData.append('emails', JSON.stringify($scope.to));
          formData.append('guid', guid);

          $http({
            method: 'POST',
            url: '/api/share_mappings',
            headers: {
              'Content-Type': undefined,
            },
            data: formData,
            transformRequest: angular.identity,
          }).then(() => {
            // create lease version
            $http({
              method: 'POST',
              url: '/create_lease_version',
              data: {
                lease_id: $rootScope.lease.id,
                prev_version_desc: `lease share to [ ${$scope.to.join(', ')} ]`,
              },
            }).then(response => {
              $rootScope.lease.instanceVersion = response.data.version;
              LeaseEditorService.setLeaseTitle();
            });
          });

          libx.log.v('exporting lease...');
          libx.di.requireUgly(['exportHelper'], async exportHelper => {
            await exportHelper.exportCurrentLease(guid);

            alert(`Lease ${guid} was successfully uploaded to ToS!`);
            libx.di.modules.network
              .httpGetString(
                exportHelper.config.tosApiServer + '/getDocumentUrl/' + guid,
              )
              .then(link => {
                console.log('download link: ', link);
              });
          });
        };

        $scope.share = function() {
          $scope.disabled = true;
          $scope.to = $('#sendTo').tagit('assignedTags');
          var sendToExternal = _.difference($scope.to, companyUsers);
          if (!sendToExternal.length) {
            sendMail();
          } else if (sendToExternal.length) {
            showExternalUserDialog(sendToExternal);
          }

          window.track.event(new ShareDocumentEvent({
            emails: $scope.to.length,
          }));
        };

        function fileName() {
          if (!$rootScope.lease.isConformedDeal) {
            return (
              $rootScope.lease.building.dashboardName +
              ' - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' - (' +
              $rootScope.lease.type.toLowerCase() +
              ') v' +
              $rootScope.lease.instanceVersion +
              '.docx'
            );
          } else {
            return (
              '[Conformed Deal] - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' - (' +
              $rootScope.lease.type.toLowerCase() +
              ') v' +
              $rootScope.lease.instanceVersion +
              '.docx'
            );
          }
        }

        $scope.$on(eventName, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', eventName);
          console.log(new Date().getTime() + ' data ', data);
          var type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES)
            .then(function successCallback(response) {
              var file = new Blob([response.data], { type: type });
              $rootScope.$emit('getFile', {
                data: { file: file, name: fileName(), type: 'share' },
              });
            },
            function errorCallback(response) {
              console.log(response);
              alert(attachErrorMessage);
            },
          );
        });

        $scope.showCompareDialog = function(event) {
          $scope.showDarkMask = true;
          $mdDialog
            .show({
              template:
                "<md-dialog><compare-modal download='false'></compare-modal></md-dialog>",
              parent: angular.element(document.body),
              targetEvent: event,
              clickOutsideToClose: false,
              skipHide: true,
              fullscreen: false,
              hasBackdrop: false,
            })
            .finally(function() {
              $scope.showDarkMask = false;
            });
        };

        var getFile = $rootScope.$on('getFile', function(event, data) {
          $scope.files[data.data.type] = data.data;

          switch (data.data.type) {
            case 'share':
              sendMail();
              break;
            case 'redLine':
              $scope.showDarkMask = false;
              $scope.files['share'] = data.modified;
              break;
            default:
              break;
          }
        });

        $scope.$on('$destroy', getFile);
      },
    };
  },
]);
